//start a new component called WhyGnS.js
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { IconCertified } from './Icons'
import useInViewPort from '../hooks/useInViewPort'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'

const WhyGnS = ({ productType }) => {
  const [nodeRef1, isVisible1] = useInViewPort()
  const [nodeRef2, isVisible2] = useInViewPort()
  const [nodeRef3, isVisible3] = useInViewPort()
  const [nodeRef4, isVisible4] = useInViewPort()
  const location = useLocation()
  return (
    <section className=" w-full gap-5 flex flex-col pt-5 overflow-x-hidden leading-loose">
      <h3 className="mx-auto max-w-max translate-y-10 border border-gray-300 bg-white px-3 py-2">
        WHY CHOOSE US
      </h3>
      <hr className="bg-gray-200 mb-5" />
      <p className="mx-auto max-w-[800px] leading-loose">
        It was 1980 when we first opened our doors for business in Miami Beach, Florida. While a lot
        has changed since then, there are fundamental principles that have governed Gray & Sons
        since day one. Principles like; offering only the highest quality merchandise coupled with
        the highest quality service while still offer our customers a substantial discount These
        principles have helped us grow into what we are today, South Florida's Top Rated Story for
        Pre-owned Fine Jewelry and Luxury Watches.
      </p>

      <div
        className={
          (location.hash === '#authenticity' ? ' why-us-highlight ' : ' ') +
          ' flex flex-row flex-wrap'
        }
      >
        <div
          className={
            (isVisible1 ? 'opacity-100 ' : 'opacity-0 ') +
            ' order-1 my-5 w-full lg:my-0 lg:w-1/2 duration-500 ease-out'
          }
          ref={nodeRef1}
        >
          <StaticImage
            alt="Gray and Sons Jewelers Miami Beach Showroom"
            src={'../images/whyGnSwebbanner3.jpg'}
            className="w-full"
          />
        </div>
        <div
          id="authenticity"
          className="order-2 flex flex-col items-center justify-center px-5 lg:w-1/2"
        >
          <h4 className="font-bold mb-5 underline-title">AUTHENTICITY GUARANTEED</h4>
          <div className="flex flex-row items-center  ">
            <IconCertified className="aspect-[3/2] w-1/3 min-w-[90px] mx-2" />
            <p className="text-sm text-gray-700 ">
              Each of our watches is inspected for authenticity by one of our Master-trained and
              certified professional watchmakers. We absolutely guarantee the authenticity of every
              watch we sell.
            </p>
          </div>
        </div>
      </div>
      <div
        className={
          (location.hash === '#return-policy' ? ' why-us-highlight ' : ' ') +
          ' flex flex-row flex-wrap '
        }
      >
        <div
          className={
            (isVisible2 ? 'opacity-100 translate-0 ' : 'opacity-0 translate-x-20') +
            ' order-1 my-5 w-full lg:order-2 lg:my-0 lg:w-1/2 duration-500 ease-out'
          }
          ref={nodeRef2}
        >
          <StaticImage
            alt="Gray and Sons Jewelers Miami Beach Showroom"
            src={'../images/WhyGnSwebbanner2.jpg'}
            className=""
          />
        </div>
        <div
          id="return-policy"
          className=" order-2 flex flex-col items-center justify-center px-5 lg:order-1 lg:w-1/2"
        >
          <h4 className="font-bold mb-5 underline-title">OUR RETURN POLICY</h4>
          <div className="text-sm text-gray-700">
            <b>We offer one of the best return policies in the business.</b>
            <ul className=" ml-3 space-y-1 py-1 list-disc list-inside">
              <li>10-Day return privilege</li>
              <li>No questions asked</li>
              <li>We will pay for the return shipping</li>
              <li>Try it, Love it or Leave it</li>
            </ul>
            Our 10 day return privilege, is unlike any other. The 10 day time period begins when you
            receive your watch or jewelry item. If for any reason you don't want to keep it, you can
            return it with no questions asked - we'll even pay for the return shipping! You can try
            it on, show it off to others, and even have it independently appraised. PLEASE NOTE: all
            special-orders, including but not limited to items not in stock, are non-refundable and
            require a 30% deposit by check or bank wire.
          </div>
        </div>
      </div>

      {/* TODO: Greg, remove these bottom 2 when the item is NOT a watch */}
      {productType === 'Watch' ? (
        <>
          <div
            id="warranty"
            className={
              (location.hash === '#warranty' ? ' why-us-highlight ' : ' ') +
              ' flex flex-row flex-wrap '
            }
          >
            <div
              className={
                (isVisible3 ? 'opacity-100 translate-0 ' : 'opacity-0 -translate-x-20') +
                ' order-1 my-5 w-full lg:my-0 lg:w-1/2 duration-500 ease-out'
              }
              ref={nodeRef3}
            >
              <StaticImage
                alt="Gray and Sons Jewelers Miami Beach Showroom"
                src={'../images/WhyGnSwebbanner1.jpg'}
                className=""
              />
            </div>

            <div className="order-2 flex flex-col items-center justify-center px-5 lg:w-1/2">
              <h4 className="font-bold mb-5 underline-title">OUR WARRANTY</h4>
              <div className="text-sm text-gray-700 ">
                Our in-house master watchmakers and authenticators have:
                <ul className=" ml-3 space-y-1 list-disc list-inside">
                  <li>Tested</li>
                  <li>Re-lubricated and Overhauled</li>
                  <li>Retored</li>
                  <li>Polished</li>
                  <li>Calibrated</li>
                </ul>
                This watch and <b>guarantee functionality for 1-2 years</b>{' '}
                <i>(modern Rolex 2 years, all other brands 1 year)</i>. Our in-house
                jewelers/detailers have polished the watch case and bracelet to{' '}
                <b>like new condition</b>.
                <br />
                Note: Occasionally, custom leather straps, bands, dials, and stones may be added
                outside the original manufacturer by the prior owner to upgrade the look of a
                certified authentic watch.
              </div>
            </div>
          </div>
          <div
            id="like-new"
            className={
              (location.hash === '#like-new' ? ' why-us-highlight ' : ' ') +
              ' flex flex-row flex-wrap '
            }
          >
            <div
              className={
                (isVisible4 ? 'opacity-100 translate-0 ' : 'opacity-0 translate-x-20 ') +
                ' order-1 my-5 w-full lg:order-2 lg:my-0 lg:w-1/2 duration-500 ease-out'
              }
              ref={nodeRef4}
            >
              <StaticImage
                alt="Gray and Sons Jewelers Miami Beach Showroom"
                src={'../images/gray-and-sons-showroom-bal-harbour-miami-beach.png'}
                className=""
              />
            </div>
            <div className=" order-2 flex flex-col items-center justify-center px-5 lg:order-1 lg:w-1/2">
              <h3 className="font-bold mb-5 underline-title">LIKE NEW FOR LIFE</h3>
              <div className="text-sm text-gray-700">
                With a minimum $2,000 pre-owned timepiece purchase, you'll receive:
                <ul className=" ml-3 space-y-1 list-disc list-inside">
                  <li>Free annual polishing</li>
                  <li>Detailing</li>
                  <li>Pressure testing</li>
                  <li>Battery replacement</li>
                </ul>
                ...as needed for as long as you own your watch. This benefit is not transferable. To
                qualify, you need only to have Gray & Sons clean your timepiece and perform a
                "relubrication overhaul" every 36 months or sooner for a nominal charge.
              </div>
            </div>
          </div>
        </>
      ) : null}
    </section>
  )
}
WhyGnS.propTypes = {
  productType: PropTypes.string.isRequired,
}

export default WhyGnS
